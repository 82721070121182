<template>
    <div>
        <header-portrate />


    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row :style="`margin-top:0;direction:`+lang.dir+`;padding-left:30px;padding-right:30xp;`">
            <v-col cols="12" class="text-end">
                <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" 
                :style="`width:150px;background:transparent;border:none`" 
                @click="resetPayType()"
                v-b-toggle.add_paytype>
              <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_pay_type}}</div></b-button>
            </v-col>
        </v-row>
        <v-simple-table style="padding-left:30px;padding-right:30xp;">
            <thead>
                <tr>
                    <th class="text-center">{{lang.namear}}</th>
                    <th class="text-center">{{lang.nameen}}</th>
                    <th class="text-center">{{lang.classid}}</th>
                    <th class="text-center" colspan="2">{{lang.action}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.type_name}}</td>
                    <td>{{item.type_name_en}}</td>
                    <td>{{item.classid}}</td>
                    <td style="width:100px;background:#000;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:#000;width:100px;height:25px !important;margin-left:5px;border:none" @click="edit(item)" v-b-toggle.add_paytype>{{lang.update}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                    <td style="width:100px;background:green;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:green;width:100px;height:25px !important;margin-left:5px;border:none" @click="getStatement(item)" v-b-toggle.show_statment>{{lang.client_statement}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <finance-statement ref="showStat" />
        <add-pay-type ref="addPaytype" />
    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import FinanceStatement from '@/components/finance-statement.vue';
import AddPayType from '@/components/addPayType.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
export default {
    components: { breadCrumbs, TabsComp, FinanceStatement, AddPayType, HeaderPortrate,Footer },
    data() {
        return {
            paytypesrows: [],
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
        }
    },
    created() {
        this.getPyamentType();
    },
    methods: {
        edit(item){
            this.$refs.addPaytype.type_name = item.type_name;
            this.$refs.addPaytype.type_name_en = item.type_name_en;
            this.$refs.addPaytype.classid = item.classid;
            this.$refs.addPaytype.id = item.id;
            this.$refs.addPaytype.getClassname(item.classid);
        },
        resetPayType(){
            this.$refs.addPaytype.type_name = '';
            this.$refs.addPaytype.type_name_en = '';
            this.$refs.addPaytype.classid = '';
            this.$refs.addPaytype.id = '';
            this.$refs.addPaytype.class_name = '';
        },
        getStatement(item){
            this.$refs.showStat.accountn = item;
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.paytypesrows = res.results.data;
                
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab2',smclass:'font-small'
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab2',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab4',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab3',smclass:'font-small'
                },
            ]
        },
        items: function() {
            return {
                    text: this.lang.paytypes,
                    disabled: true,
                    to: '/finance/paytypes',
                }
        },
       
    },
}
</script>